import { Add, BeachAccess, Delete, InfoOutlined } from "@mui/icons-material";
import { Card, IconButton, Stack } from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDictionary, useUser } from "../hooks";
import { addMonthsToDate, renderHeader } from "../utilities";
import HeaderCollapse from "./HeaderCollapse";
import RequestsGeneralPanel from "./RequestsGeneralPanel";
import { AbsenceContext } from "../contexts";
import EventBusyIcon from '@mui/icons-material/EventBusy';

const AbsencePanel = () => {
  const {
    state: { absence },
    setAbsence,
    addAbsence,
    deleteAbsence,
  } = useContext(AbsenceContext);
  const {user} = useUser();
  const dictionary = useDictionary();

  useEffect(() => {
    setAbsence(user.id);
  }, []);

  return (
    <Card>
      <HeaderCollapse
        color={"requests"}
        title={dictionary["dashboard"]["absenceRequests"]}
        notificationCount={absence?.length}
        Icon={EventBusyIcon}
        actions={
          <Stack direction="row" justifyContent="center" alignItems="center">
            <IconButton
            id="add-absence-request"
              onClick={() => addAbsence(user.id, dictionary)}
              sx={{ color: (theme) => theme.palette.common.white }}
              size="small"
            >
              <Add fontSize="small" />
            </IconButton>
            <IconButton
              sx={{ color: (theme) => theme.palette.common.white }}
              size="small"
              component={Link}
              to="/approvers"
            >
              <InfoOutlined fontSize="small" />
            </IconButton>
          </Stack>
        }
      />
      <RequestsGeneralPanel
        requests={absence}
        columns={[
          {
            headerName: dictionary["from"],
            renderHeader: renderHeader(dictionary["from"]),
            field: "datefrom",
            flex: 2,
            sortable: false,
            renderCell: ({ value }) =>
              moment(new Date(value)).format("DD/MM/YYYY"),
          },
          {
            headerName: dictionary["to"],
            renderHeader: renderHeader(dictionary["to"]),
            field: "dateto",
            sortable: false,
            flex: 2,
            renderCell: ({ value }) =>
              moment(new Date(value)).format("DD/MM/YYYY"),
          },
          {
            headerName: dictionary["absence"]["days"],
            renderHeader: renderHeader(dictionary["absence"]["days"]),
            flex: 1,
            sortable: false,
            field: "days",
          },
          {
            headerName: dictionary["actions"],
            renderHeader: renderHeader(dictionary["actions"]),
            flex: 1,
            sortable: false,
            field: "",
            renderCell: ({ row }) => {
              if (row.status !== "DELETED" && row.status !== "CANCELLED") {
                return (
                  <Stack direction="row">
                    <IconButton
                    id="delete-absence-request"
                      onClick={() => {
                        deleteAbsence(row, dictionary, setAbsence, user.id);
                      }}
                      aria-label="delete-request"
                      color="error"
                      size="small"
                    >
                      <Delete fontSize="inherit" />
                    </IconButton>
                  </Stack>
                );
              } else {
                return <></>;
              }
            },
          },
        ]}
      />
    </Card>
  );
};

export default AbsencePanel;
