import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import { routes, userHasOneOfRoles, findBreadcrumb } from "../utilities";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  CardMedia,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useDictionary, useUser } from "../hooks";
import { AuthContext, LanguageContext, ThemeContext } from "../contexts";
import { ExpandLess, ExpandMore, Logout } from "@mui/icons-material";
import Route from "./Route";
import UserBadge from "./UserBadge";

const AppBarMenu = () => {
  const dictionary = useDictionary();
  const { logout } = React.useContext(AuthContext);
  const location = useLocation();
  const { openSidebar } = React.useContext(ThemeContext);
  const [opens, setOpens] = React.useState({});
  const { user } = useUser();

  const {
    state: { menuOpened, languages, languageAbbr },
    openMenu,
    closeMenu,
    onChangeLanguage,
  } = React.useContext(LanguageContext);

  const navigate = useNavigate();

  return (
    <AppBar
      position="static"
      sx={{
        height: "64px",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Typography
            noWrap
            component="div"
            sx={{
              mr: 2,
              display: { xs: "none", md: "none", lg: "flex" },
              overflow: "hidden",
              maxWidth: "200px", // Regola la dimensione massima
            }}
          >
            <CardMedia
              component="img"
              image={require("../assets/main_logo_bp.png")}
              alt="logo"
              sx={{
                maxHeight: "55px",
              }}
            />
          </Typography>

          <Box
            sx={{
              flexGrow: 0,
              display: { xs: "flex", md: "flex", lg: "none" },
            }}
          >
            <IconButton
              size="large"
              aria-label="menu-appbar-label"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={openSidebar}
              color="inherit"
            >
              <MenuIcon id="appbar" />
            </IconButton>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "flex", lg: "none" },
            }}
          >
            {dictionary["routes"][findBreadcrumb()]}
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "none", lg: "flex" },
            }}
          >
            <List sx={{ display: "flex", flexDirection: "row" }}>
              {routes.map(
                (route) =>
                  route.inAppbar &&
                  (route.children &&
                  route.children.length > 0 &&
                  userHasOneOfRoles(route.need, user) ? (
                    <React.Fragment key={route.id}>
                      <ListItemButton
                        dense
                        onClick={(e) =>
                          setOpens((_) => ({
                            ..._,
                            [route.breadcrumb]: Boolean(_[route.breadcrumb])
                              ? undefined
                              : e.currentTarget,
                          }))
                        }
                      >
                        <ListItemText>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            {dictionary["routes"][route.breadcrumb]}
                            {opens[route.breadcrumb] ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </Box>
                        </ListItemText>
                      </ListItemButton>
                      <Menu
                        onClose={() =>
                          setOpens((_) => ({
                            ..._,
                            [route.breadcrumb]: undefined,
                          }))
                        }
                        open={Boolean(opens[route.breadcrumb])}
                        anchorEl={opens[route.breadcrumb]}
                      >
                        {route.children.map(
                          (child) =>
                            child.inAppbar && (
                              <Route
                                dictionary={dictionary}
                                icon
                                sx={{ mb: 0 }}
                                parent={route}
                                close={() =>
                                  setOpens((_) => ({
                                    ..._,
                                    [route.breadcrumb]: undefined,
                                  }))
                                }
                                dense
                                key={child.id}
                                location={location}
                                route={child}
                              />
                            )
                        )}
                      </Menu>
                    </React.Fragment>
                  ) : (
                    <Route
                      key={route.id}
                      className={"in-navbar"}
                      dictionary={dictionary}
                      inNavbar
                      sx={{ mb: 0 }}
                      dense
                      route={route}
                      location={location}
                    />
                  ))
              )}
            </List>
            <UserBadge user={user} />
          </Box>

          <Box
            sx={{
              height: 32,
              flexGrow: 0,
              position: "absolute",
              right: { xs: 5, md: 5, lg: -115 },
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button
              sx={{
                color: (theme) => theme.palette.common.white,
                textTransform: "uppercase",
                // mr: (theme) => theme.spacing(1),
                py: 0,
              }}
              id="change-language-button"
              size="small"
              onClick={openMenu}
            >
              {languageAbbr}
            </Button>
            <Menu
              id="menu-appbar"
              anchorEl={document.getElementById("change-language-button")}
              open={menuOpened}
              onClose={closeMenu}
            >
              {Object.entries(languages).map(([abr, language]) => (
                <MenuItem key={abr} onClick={() => onChangeLanguage(abr)}>
                  {language.label}
                </MenuItem>
              ))}
            </Menu>
            <IconButton
              sx={{ color: (theme) => theme.palette.common.white }}
              onClick={() => logout(navigate)}
            >
              <Logout />
            </IconButton>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default AppBarMenu;
