import React from "react";
import { Avatar, Typography, Box } from "@mui/material";
import { useUser } from "../hooks";

const UserBadge = () => {
  const { user, image } = useUser();

  if (!user) {
    return null;
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      gap={0.5} // Distanza ridotta tra Avatar e testo
      sx={{
        padding: 1, // Rimuoviamo il padding per risparmiare spazio
        width: "auto", // Larghezza automatica, per adattarsi al contenuto
        maxWidth: 75, // Impostiamo una larghezza massima, se necessario
      }}
    >
      {image ? (
        <Avatar
          alt={`${user.firstname} ${user.lastname}`}
          src={image}
          sx={{ width: 30, height: 30 }} // Impostiamo una dimensione più piccola per l'Avatar
        />
      ) : (
        <Avatar sx={{ width: 32, height: 32 }}>
          {user.firstname[0]}
          {user.lastname[0]}
        </Avatar>
      )}
      <Typography
        variant="body2" // Cambia la variante del testo per farlo più piccolo
        fontWeight="bold"
        sx={{ fontSize: "0.90rem" }} // Puoi anche regolare la dimensione del font in base alle tue esigenze
      >
        {user.firstname.length > 9
          ? `${user.firstname.slice(0, 9)}`
          : user.firstname}
      </Typography>
    </Box>
  );
};

export default UserBadge;
