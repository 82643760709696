import createContext from './createContext';
import it from '../assets/dictionaries/it.json';
import en from '../assets/dictionaries/en.json';
import { itIT as itITGrid, enUS as enUSGrid } from '@mui/x-data-grid';
import { itIT, enUS } from '@mui/material/locale';
import moment from 'moment';
import "moment/locale/it";
import "moment/locale/en-gb";
import itITCalendar from "moment/locale/it";
import enUSCalendar from "moment/locale/en-gb";

const muiTranslationsToApply = {
    "it": {
        grid: itITGrid, 
        base: itIT,
        calendar: itITCalendar,
        calendarAbbr: "it"
    },
    "en": { 
        grid: enUSGrid, 
        base: enUS,
        calendar: enUSCalendar,
        calendarAbbr: "en-gb"
    }
}
const CHANGE_LANGUAGE = 'change_language';
const OPEN_LANGUAGE_MENU = 'open_language_menu';
const CLOSE_LANGUAGE_MENU = 'close_language_menu';

const INITIAL_STATE = {
    dictionary: it,
    muiTranslation: muiTranslationsToApply.it,
    language: 'Italiano',
    languageAbbr: 'it',
    menuOpened: false,
    languages: {
        'en': {
            label: 'English',
            dictionary: en,
            muiTranslations: muiTranslationsToApply.en
        },
        'it': {
            label: 'Italiano',
            dictionary: it,
            muiTranslations: muiTranslationsToApply.it
        }
    }
};

const reducer = (state, action) => {
    switch(action.type) {
        case CHANGE_LANGUAGE: {
            localStorage.setItem(process.env.REACT_APP_LANGUAGE_PREFERENCE, action.payload);
            moment.locale(action.payload);
            moment.updateLocale(action.payload, {
                week: {
                    dow : 1
                }
            })
            return { ...state, menuOpened: false, languageAbbr: action.payload, muiTranslation: state.languages[action.payload].muiTranslations, language: state.languages[action.payload].label, dictionary: state.languages[action.payload].dictionary };
        }
        case OPEN_LANGUAGE_MENU: {
            return { ...state, menuOpened: true };
        }
        case CLOSE_LANGUAGE_MENU: {
            return { ...state, menuOpened: false };
        }
        default: {
            return state;
        }
    }
}

const onChangeLanguage = (dispatch) => (language) => {
    dispatch({ type: CHANGE_LANGUAGE, payload: language });
};
const openMenu = (dispatch) => () => {
    dispatch({ type: OPEN_LANGUAGE_MENU });
};
const closeMenu = (dispatch) => () => {
    dispatch({ type: CLOSE_LANGUAGE_MENU });
};

export const { Context, Provider } = createContext(
    reducer,
    {
        onChangeLanguage,
        openMenu,
        closeMenu,
        // ACTIONS
    },
    INITIAL_STATE
);