import {DataGrid} from "@mui/x-data-grid";
import React from "react";
import {ROWS_PER_PAGE} from "../hooks/usePagination";
import {useDictionary, usePagination} from "../hooks";
import {renderHeader,translateMinutesInTime, generateUuid} from "../utilities";

const BankHoursTables = ({mini = false, onlyView = false, searchedBankHours, types = [], searchForm}) => {
  const [pageSize, setPageSize] = usePagination();
  /* const justificationTypes = useJustificationsTypes();
  const {user} = useUser(); */
  const dictionary = useDictionary();
  /* const [isMobile] = useMediaQuery("down", "md"); */

  const columnsDash = [
    {
      field: "balanceyear",
      headerName: dictionary["year"],
      renderHeader: renderHeader(dictionary["year"]),
      flex: 2
    },
    {
      field: "balance",
      headerName: dictionary["bankHours"]["balance2"],
      renderHeader: renderHeader(dictionary["bankHours"]["balance2"]),
      flex: 1,
      renderCell: ({value}) => translateMinutesInTime(value),
    },
  ];

  let columns = [];

 
  return (
    <DataGrid
      disableColumnMenu
      disableSelectionOnClick
      autoHeight
      getRowId={() => generateUuid()}
      rows={searchedBankHours}
      columns={mini ? columnsDash : onlyView ? columnsOnlyView : columns}
      density={mini ? "compact" : "standard"}
      pagination
      hideFooter={mini || onlyView}
      onPageSizeChange={setPageSize}
      pageSize={mini || onlyView ? 100 : pageSize}
      rowsPerPageOptions={ROWS_PER_PAGE}
    />
  );
};

export default BankHoursTables;
