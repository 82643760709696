import {IconButton, Stack, Typography, Button} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import React from "react";
import {ROWS_PER_PAGE} from "../hooks/usePagination";
import {useDictionary, useEventTypes, useMediaQuery, usePagination} from "../hooks";
import moment from "moment";
import {Block, Done, LaunchTwoTone} from "@mui/icons-material";
import {openModal} from "../hooks/useModals";
import AddNotes from "./AddNotes";
import {Note} from "../components";
import {deleteMillisFromTime} from "../utilities";

const ManageRequestsTables = ({mini = false, searchedRequests, approveManageRequests}) => {
  const [pageSize, setPageSize] = usePagination();
  const dictionary = useDictionary();
  const [isMobile] = useMediaQuery("down", "md");

  const openModalNotes = (r) => {
    openModal(
      dictionary["notes"],
      <Note richiesta={{...r.request, outcomes: [...r.outcomes]}} />,
      undefined,
      (closeModal) => (
        <Stack direction="row" spacing={2}>
          <Button
            color="error"
            size="small"
            variant="contained"
            onClick={() => {
              closeModal();
            }}>
            {dictionary["close"]}
          </Button>
        </Stack>
      ),
      "md"
    );
  };

  const columnsDash = [
    {
      field: "userfirstname",
      headerName: dictionary["user"],
      flex: 1,
      valueGetter: (params) => params.row.userfirstname + " " + params.row.userlastname,
    },
    {
      field: "type",
      headerName: dictionary["type"],
      flex: 1,
      valueGetter: (params) => dictionary["requests"]["types"][params.row.request.requesttype],
    },
  ];

  const Approved = (row) => {
    let data = [];
    data = {...row, status: "APPROVED"};
    approveManageRequests(row.id, data);
  };

  // const NotApproved = (row) => {
  //   let data = [];
  //   data = { ...row, status: "NOT_APPROVED" };
  //   approveManageRequests(row.id, data);
  // };

  const NotApproved = (row) => {
    let data = [];
    const action = async (form, closeModal) => {
      data = {...row, status: "NOT_APPROVED", notes: form.notes};
      approveManageRequests(row.id, data);
      closeModal();
    };
    const form = {notes: ""};
    openModal(dictionary["note"], <AddNotes formRef={form} />, "info", (closeModal) => (
      <Stack direction="row" spacing={2}>
        <Button
          color="success"
          variant="contained"
          size="small"
          onClick={async () => {
            await action(form, closeModal);
          }}>
          {dictionary["confirm"]}
        </Button>
        <Button color="error" variant="contained" size="small" onClick={closeModal}>
          {dictionary["cancel"]}
        </Button>
      </Stack>
    ));
  };

  let columns = [];

  const columnsDesk = [
    {
      field: "userfirstname",
      headerName: dictionary["user"],
      flex: 1,
      renderCell: (params) => params.row.userfirstname + " " + params.row.userlastname,
    },
    {
      field: "type",
      headerName: dictionary["type"],
      renderCell: (params) => dictionary["requests"]["types"][params.row.request.requesttype],
    },
    {
      field: "periods",
      headerName: dictionary["managerequests"]["period"],
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack spacing={0.5}>
            <Stack direction="row" spacing={0.5} mt={1}>
              <Typography sx={{fontWeight: "bold"}} variant="body2">
                {dictionary["reports"]["dateFrom"]}:{" "}
              </Typography>
              <Typography variant="body2">{`${moment(params.row.request.datefrom).format("DD/MM/YYYY")} ${
                params.row.request.startinghour ? params.row.request.startinghour : ""
              }`}</Typography>
            </Stack>
            <Stack direction="row" spacing={0.5}>
              <Typography sx={{fontWeight: "bold"}} variant="body2">
                {dictionary["reports"]["dateTo"]}:{" "}
              </Typography>
              <Typography variant="body2">{`${moment(params.row.request.dateto).format("DD/MM/YYYY")} ${
                params.row.request.endinghour ? params.row.request.endinghour : ""
              }`}</Typography>
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: "sendDatetime",
      headerName: dictionary["managerequests"]["forwardedon"],
      flex: 0.5,
      renderCell: ({row}) => {
        const [date, time] = row.request.requestdatetime.split("T");
        return (
          <Stack spacing={0.5}>
            <Stack direction="row" spacing={0.5} mt={1}>
              <Typography variant="body2">{moment(date).format("DD/MM/YYYY")}</Typography>
            </Stack>
            <Stack direction="row" spacing={0.5}>
              <Typography variant="body2">{deleteMillisFromTime(time)}</Typography>
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: "",
      headerName: dictionary["managerequests"]["dayshours"],
      renderCell: ({row}) => {
        // FIX TEMPORANEO, VEDERE MEGLIO COME DISTINGUERE HOLIDAY AND PERMIT AND BUSINESS TRIP
        switch (row.request.requesttypeid) {
          case 3:
          case 6:
            // HOLIDAY
            return `${row.request.days} ${dictionary["holidays"]["days"]}`;
          case 5:
            // PERMIT
            return `${row.request.minutes / 60} ${dictionary["hours"]}`;
          case 7:
            // ABSENCE
            return `${row.request.days} ${dictionary["absence"]["days"]}`;
          default:
            return "Type not supported";
        }
      },
    },
    /* {
          field: "notes",
          headerName: "Nota",
          flex: 1,
        }, */
    {
      field: "status",
      headerName: dictionary["managerequests"]["statusrequests"],
      renderCell: ({row}) => dictionary["requestStatus"][row.request.status],
    },
    {
      field: "notes",
      headerName: dictionary["notes"],
      renderCell: ({row}) => (
        <Stack direction={"row"} spacing={2}>
        {/*   {row.request.notes ? ( */}
            <IconButton aria-label="delete" size="small" onClick={() => openModalNotes(row)}>
              <LaunchTwoTone fontSize="inherit" />
            </IconButton>
         {/*  ) : (
            <Typography variant="body2">-</Typography>
          )} */}
        </Stack>
      ),
    },
    {
      field: "actions",
      headerName: dictionary["actions"],
      renderCell: ({row}) => (
        <Stack direction="column" justifyContent={"flex-end"}>
          {row.request.status !== "NEW" ? (
            row.status === "PENDING" ? null : (
              <Typography variant="text">{dictionary["requestStatus"][row.status]}</Typography>
            )
          ) : (
            <Stack direction="row">
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  Approved(row);
                }}
                aria-label="delete-request"
                color="info"
                size="small"
                disabled={row.status !== "PENDING" || row.request.status !== "NEW"}>
                <Done fontSize="inherit" />
              </IconButton>
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  NotApproved(row);
                }}
                disabled={row.status !== "PENDING" || row.request.status !== "NEW"}
                aria-label="delete-request"
                color="error"
                size="small">
                <Block fontSize="inherit" />
              </IconButton>
            </Stack>
          )}
        </Stack>
      ),
    },
  ];

  const columnsMobile = [
    {
      field: "userfirstname",
      headerName: dictionary["user"],
      flex: 1,
      renderCell: (params) => params.row.userlastname,
    },
    {
      field: "periods",
      headerName: dictionary["managerequests"]["period"],
      flex: 2,
      renderCell: (params) => {
        return (
          <Stack spacing={0.5}>
            <Stack direction="row" spacing={0.5} mt={1}>
              <Typography variant="body2">{`${moment(params.row.request.datefrom).format("DD/MM/YYYY")} ${
                params.row.request.startinghour ? params.row.request.startinghour : ""
              }`}</Typography>
            </Stack>
            <Stack direction="row" spacing={0.5}>
              <Typography variant="body2">{`${moment(params.row.request.dateto).format("DD/MM/YYYY")} ${
                params.row.request.endinghour ? params.row.request.endinghour : ""
              }`}</Typography>
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: "status",
      flex: 1,
      headerName: dictionary["managerequests"]["statusrequests"],
      renderCell: (params) => dictionary["requestStatus"][params.row.request.status].substring(0, 5) + ".",
    },
    {
      field: "notes",
      headerName: dictionary["notes"],
      renderCell: ({row}) => (
        <Stack direction={"row"} spacing={2} justifyContent={"center"}>
          {/* {row.request.notes ? ( */}
            <IconButton aria-label="delete" size="small" onClick={() => openModalNotes(row)}>
              <LaunchTwoTone fontSize="inherit" />
            </IconButton>
         {/*  ) : (
            <Typography variant="body2">-</Typography>
          )} */}
        </Stack>
      ),
    },
    {
      field: "actions",
      flex: 1,
      headerName: dictionary["actions"],
      renderCell: ({row}) => (
        <Stack direction="column" justifyContent={"flex-end"}>
          {row.status !== "PENDING" || row.request.status !== "NEW" ? (
            <Typography variant="text">{dictionary["requestStatus"][row.status]}</Typography>
          ) : (
            <></>
          )}
          {row.status !== "PENDING" || row.request.status !== "NEW" ? (
            <></>
          ) : (
            <Stack direction="row">
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  Approved(row);
                }}
                aria-label="delete-request"
                color="info"
                size="small"
                disabled={row.status !== "PENDING" || row.request.status !== "NEW"}>
                <Done fontSize="inherit" />
              </IconButton>
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  NotApproved(row);
                }}
                disabled={row.status !== "PENDING" || row.request.status !== "NEW"}
                aria-label="delete-request"
                color="error"
                size="small">
                <Block fontSize="inherit" />
              </IconButton>
            </Stack>
          )}
        </Stack>
      ),
    },
  ];

  isMobile ? (columns = columnsMobile) : (columns = columnsDesk);
  return (
    <DataGrid
      disableSelectionOnClick
      autoHeight
      rowHeight={mini ? 60 : 100}
      density={mini ? "compact" : "standard"}
      rows={searchedRequests}
      columns={mini ? columnsDash : columns}
      pagination
      hideFooter={mini}
      onPageSizeChange={setPageSize}
      pageSize={mini ? 100 : pageSize}
      rowsPerPageOptions={ROWS_PER_PAGE}
    />
  );
};

export default ManageRequestsTables;
