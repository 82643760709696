import { Typography, Stack, Divider, Box, Chip } from "@mui/material";
import React from "react";
import moment from "moment";
import { useDictionary, useJustificationsTypes } from "../hooks";
import { deleteMillisFromTime, translateMinutesInTime } from "../utilities";

const DeclaredTimeDiscordantAnomaly = ({ anomaly, dictionary }) => {
  const period = anomaly.periods.replaceAll("|", "");
  const periodSplit = period.split(" - ");
  const [declareddate, declaredtime] = periodSplit[0].split("T");
  const [makeddate, makedtime] = periodSplit[1].split("T");

  return (
    <>
      <Typography variant="body1">
        <span style={{ fontWeight: "bold" }}>
          {dictionary["reports"]["declared"]}
        </span>
        {" >> "}
        {moment(declareddate).format("ddd:")}&nbsp;
        {moment(declareddate).format("DD/MM/YYYY")} -&nbsp;
        {deleteMillisFromTime(declaredtime)}
      </Typography>
      <Typography variant="body1">
        <span style={{ fontWeight: "bold" }}>
          {dictionary["reports"]["maked"]}
        </span>
        {" >> "}
        {moment(makeddate).format("ddd:")}&nbsp;
        {moment(makeddate).format("DD/MM/YYYY")} -&nbsp;
        {deleteMillisFromTime(makedtime)}
      </Typography>
    </>
  );
};

const AnomalyDetailModal = ({ anomaly }) => {
  const period = anomaly.periods.replaceAll("|", "");
  const periodSplit = period.split(" - ");
  const dictionary = useDictionary();
  const justificationTypes = useJustificationsTypes();

  const [datefrom, timefrom] = anomaly.datetimefrom.split("T");
  const [dateto, timeto] = anomaly.datetimeto.split("T");
  const [declareddate, declaredtime] = periodSplit[0].split("T");
  const [makeddate, makedtime] = periodSplit[1].split("T");

  return (
    <Stack>
      {anomaly.typeid == 6 ? (
        <DeclaredTimeDiscordantAnomaly
          anomaly={anomaly}
          dictionary={dictionary}
        />
      ) : (
        <>
          <Typography variant="body1">
            <span style={{ fontWeight: "bold" }}>{dictionary["hours"]}:</span>
            {translateMinutesInTime(anomaly.minutescount)}
          </Typography>
          {anomaly.typeid !== 8 ? (
            <>
              <Typography variant="body1">
                <span style={{ fontWeight: "bold" }}>
                  {dictionary["reports"]["dateFrom"]}
                </span>
                {" >> "}
                {moment(datefrom).format("ddd:")}&nbsp;
                {moment(datefrom).format("DD/MM/YYYY")} -&nbsp;
                {deleteMillisFromTime(timefrom)}
              </Typography>
              <Typography variant="body1">
                <span style={{ fontWeight: "bold" }}>
                  {dictionary["reports"]["dateTo"]}
                </span>
                {" >> "}
                {moment(dateto).format("ddd:")}&nbsp;
                {moment(dateto).format("DD/MM/YYYY")} -&nbsp;
                {deleteMillisFromTime(timeto)}
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="body1">
                <span style={{ fontWeight: "bold" }}>
                  {dictionary["reports"]["dateFrom"]}
                </span>
                {" >> "}
                {moment(datefrom).format("ddd:")}&nbsp;
                {moment(datefrom).format("DD/MM/YYYY")} -&nbsp;
                {deleteMillisFromTime(timefrom)}
              </Typography>
              <Typography variant="body1">
                <span style={{ fontWeight: "bold" }}>
                  {dictionary["reports"]["dateTo"]}
                </span>
                {" >> "}
                {moment(dateto).format("ddd:")}&nbsp;
                {moment(dateto).format("DD/MM/YYYY")} -&nbsp;
                {deleteMillisFromTime(timeto)}
              </Typography>
              <Typography variant="body1">
               <div style={{ fontWeight: "", marginTop: "10px" }}>
                  {dictionary["reports"]["period"] + ":"}
                </div>
                <Divider></Divider>
                <span style={{ fontWeight: "bold" }}>
                  {dictionary["reports"]["declared"]}
                </span>
                <span style={{ fontWeight: "bold" }}>
                  {dictionary["reports"]["declared"]}
                </span>
                {" >> "}
                {moment(declareddate).format("ddd:")}&nbsp;
                {moment(declareddate).format("DD/MM/YYYY")} -&nbsp;
                {deleteMillisFromTime(declaredtime)}
              </Typography>
              <Typography variant="body1">
                <span style={{ fontWeight: "bold" }}>
                  {dictionary["reports"]["maked"]}
                </span>
                {" >> "}
                {moment(makeddate).format("ddd:")}&nbsp;
                {moment(makeddate).format("DD/MM/YYYY")} -&nbsp;
                {deleteMillisFromTime(makedtime)}
              </Typography>
            </>
          )}
        </>
      )}
      {anomaly.justifications.length > 0 ? (
        <Stack spacing={2}>
          <Divider />
          {anomaly.justifications.map((item, index) => (
            <Box key={`${index}-anomaly-just`}>
              {item.status === "APPROVED" ? (
                <Chip
                  sx={{ width: "100%" }}
                  size="small"
                  label={dictionary["requestStatus"][item.status]}
                  color="success"
                />
              ) : null}
              {item.status === "NOT_APPROVED" ? (
                <Chip
                  sx={{ width: "100%" }}
                  size="small"
                  label={dictionary["requestStatus"][item.status]}
                  color="error"
                />
              ) : null}
              <Typography variant="body1" marginTop="8px">
                <span style={{ fontWeight: "bold"}}>
                  {dictionary["type"]}:
                </span>
                {
                  justificationTypes?.find((just) => just.id === item.typeid)
                    ?.description
                }
              </Typography>
              {translateMinutesInTime(item.minutescount) !== "00:00" ? (
                <Typography variant="body1">
                  <span style={{ fontWeight: "bold" }}>
                    {dictionary["hours"]}:
                  </span>
                  {translateMinutesInTime(item.minutescount)}
                </Typography>
              ) : null}
              {item.outcomesnotes && item.outcomesnotes[0] && (
                <Typography variant="body1">
                  <span style={{ fontWeight: "bold" }}>
                    {dictionary["notes"]}:
                  </span>
                  {item.outcomesnotes[0].value}
                </Typography>
              )}
            </Box>
          ))}
        </Stack>
      ) : null}
    </Stack>
  );
};

export default AnomalyDetailModal;
