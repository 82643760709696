import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React, { useEffect } from "react";
import { useDictionary, useForm } from "../hooks";
import moment from "moment";
import { isDateGreater, isRequired } from "../hooks/useForm";
import { BaseNoLoaderService } from "../api";
import useAbsenceTypes from "../hooks/useAbsenceTypes";

const AddAbsence = ({ formRef }) => {
  const DATE_FORMAT = "YYYY-MM-DD";
  const TIME_FORMAT = "HH:mm";
  const now = new Date();
  const INITIAL_STATE = {
    note: "",
    dateto: moment(now).add(1, "days").format(DATE_FORMAT),
    datefrom: moment(now).add(1, "days").format(DATE_FORMAT),
    days: 1,
    justificationid: -1,
  };
  const dictionary = useDictionary();
  const validations = [
    ({ datefrom, dateto }) =>
      isDateGreater(datefrom, dateto) || {
        datefrom: dictionary["absence"]["form"]["errors"]["fromGreaterTo"],
        dateto: dictionary["absence"]["form"]["errors"]["fromGreaterTo"],
      },
    ({ datefrom }) =>
      isDateGreater(now, datefrom) || {
        datefrom: dictionary["absence"]["form"]["errors"]["fromInPast"],
      },
    ({ dateto }) =>
      isDateGreater(now, dateto) || {
        dateto: dictionary["absence"]["form"]["errors"]["toInPast"],
      },
    ({ datefrom }) =>
      isRequired(datefrom) || {
        datefrom: dictionary["absence"]["form"]["errors"]["fromRequired"],
      },
    ({ dateto }) =>
      isRequired(dateto) || {
        dateto: dictionary["absence"]["form"]["errors"]["toRequired"],
      },
  ];

  useEffect(() => {
    formRef.note = INITIAL_STATE.note;
    formRef.dateto = INITIAL_STATE.dateto;
    formRef.datefrom = INITIAL_STATE.datefrom;
    formRef.days = INITIAL_STATE.days;
    formRef.justificationid = INITIAL_STATE.justificationid;
  }, []);

  const absenceTypes = useAbsenceTypes();

  const { values, isValid, errors, touched, changeHandler } = useForm(
    INITIAL_STATE,
    validations
  );

  formRef.isValid = isValid;

  const onDateChange = async (newValue, property) => {
    formRef[property] = newValue?.format(DATE_FORMAT);
    // FIX HOLIDAY DAYS CALCULATION
    const result = await BaseNoLoaderService.get(
      `profiles/workingdays/${formRef.datefrom}/${formRef.dateto}`
    );
    formRef.days = result.data.total;
    // formRef.days = dateDiff(formRef.dateto, formRef.datefrom) + 1;
    changeHandler(
      property,
      newValue
    ); /* questa la faccio qui così posso vedere formRef.days aggiornato sulla UI, se lo facessi prima vedrei il valore di formRef.days precedente */
  };

  return (
    <Grid container spacing={2} sx={{ mt: (theme) => theme.spacing(1) }}>
      <Grid item xs={12} md={12} lg={12}>
        <DatePicker
          inputFormat="DD/MM/YYYY"
          label={dictionary["absence"]["form"]["from"]}
          value={values.datefrom || ""}
          onChange={
            (newValue) /* it is moment formatted */ => {
              onDateChange(newValue, "datefrom");
            }
          }
          renderInput={(params) => (
            <TextField
              id="absence-from"
              {...params}
              fullWidth
              size="small"
              error={Boolean(touched.datefrom && errors.datefrom)}
              helperText={touched.datefrom && errors.datefrom}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <DatePicker
          inputFormat="DD/MM/YYYY"
          label={dictionary["absence"]["form"]["to"]}
          value={values.dateto || ""}
          onChange={
            (newValue) /* it is moment formatted */ => {
              onDateChange(newValue, "dateto");
            }
          }
          renderInput={(params) => (
            <TextField
              id="absence-to"
              {...params}
              fullWidth
              size="small"
              error={Boolean(touched.dateto && errors.dateto)}
              helperText={touched.dateto && errors.dateto}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <FormControl
          error={Boolean(touched.justificationid && errors.justificationid)}
          fullWidth
          size="small"
        >
          <InputLabel id="absenceType-select">
            {dictionary["anomalies"]["justification"]}
          </InputLabel>
          <Select
            id="eventType-select"
            value={values.justificationid || ""}
            onChange={(e) => {
              changeHandler("justificationid", e.target.value);
              formRef.justificationid = e.target.value;
            }}
            label={dictionary["anomalies"]["justification"]}
          >
            {!absenceTypes.length && (
              <MenuItem value="">
                {dictionary["anomalies"]["no_justification"]}
              </MenuItem>
            )}
            {absenceTypes.map((abs, i) => (
              <MenuItem key={i} value={abs.id}>
                {abs.description}
              </MenuItem>
            ))}
          </Select>
          {Boolean(touched.justificationid && errors.justificationid) && (
            <FormHelperText>
              {touched.justificationid && errors.justificationid}
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      {formRef.days > 0 && (
        <Grid item xs={12} md={12} lg={12}>
          <Typography variant="body2">
            {dictionary["absence"]["form"]["totDays"]}
            {": "}
            {formRef.days}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} md={12} lg={12}>
        <TextField
          size="small"
          minRows={2}
          maxRows={7}
          fullWidth
          multiline
          label={dictionary["note"]}
          value={values.note}
          onChange={({ target }) => {
            changeHandler("note", target.value);
            formRef.note = target.value;
          }}
        />
      </Grid>
    </Grid>
  );
};

export default AddAbsence;
