import createDataContext from "./createContext";
import { BaseService, UsersService } from "../api";
import moment from "moment";
import { openModal } from "../hooks/useModals";
import { Button, Grid, Stack } from "@mui/material";
import { AddAbsence, ClockingForm } from "../components";
import { sendNotification } from "../hooks/useNotification";

const SET_ABSENCE = "set_absence";
const SET_ABSENCE_TYPE = "set_absence_type";

const INITIAL_STATE = {
    absence: [],
    absenceTypes: [],
};

const absenceReducer = (state, action) => {
    switch (action.type) {
        case SET_ABSENCE: {
            const { absence } = action.payload;
            return { ...state, absence };
        }
        case SET_ABSENCE_TYPE: { // nuovo caso
            const { absenceTypes } = action.payload;
            return { ...state, absenceTypes };
        }
        default: {
            return state;
        }
    }
};


const setAbsence = dispatch => async (userid) => {
    const today = moment(new Date()).format("YYYY-MM-DD");
    const response = await UsersService.get(`${userid}/absences/${today}/ALL`);
    console.log(response.data.absences)
    dispatch({ type: SET_ABSENCE, payload: { absence: response.data.absences } });
};

const setAbsenceTypes = (dispatch) => async () => {
    const response = await BaseService.get(`justifications/type/country/current/ABSENCE`);
    dispatch({ type: SET_ABSENCE_TYPE, payload:{absenceTypes: response.data.justificationstypes }});
  };

const addAbsence = dispatch => async (userid, dictionary) => {
    const action = async (form, closeModal) => {
        if (form.isValid) {
            await UsersService.post(`/${userid}/absence`, {
                datefrom: form.datefrom,
                dateto: form.dateto,
                userid,
                request_type: "ABSENCE",
                status: "NEW",
                days: form.days,
                notes: form.note,
                justificationid: form.justificationid
            })
            sendNotification(
                dictionary["absence"]["addSuccess"]["title"],
                dictionary["absence"]["addSuccess"]["message"],
                "success",
                5
            );
            setAbsence(dispatch)(userid);
            closeModal();
        } else {
            sendNotification(
                dictionary["absence"]["form"]["errors"]["invalid"]["title"],
                dictionary["absence"]["form"]["errors"]["invalid"]["message"],
                "error",
                5
            );
        }

    };
    const form = { note: '', datefrom: '', dataeto: '', days: 1, justificationid: -1 };
    openModal(
        dictionary["absence"]["request"],
        <AddAbsence formRef={form} />,
        "info",
        closeModal => (
            <Stack direction="row" spacing={2}>
                <Button
                id="confirm-absence-request"
                    color="success"
                    variant="contained"
                    size="small"
                    onClick={async () => {
                        await action(form, closeModal);
                    }}>
                    {dictionary["confirm"]}
                </Button>
                <Button
                    color="error"
                    variant="contained"
                    size="small"
                    onClick={closeModal}
                >
                    {dictionary["cancel"]}
                </Button>
            </Stack>
        ));
};
const deleteAbsence = dispatch => async (request, dictionary, setRequests, INITIAL_STATE) => {
    const action = async (closeModal) => {
        await BaseService.put(`/requests/${request.id}/NOT_APPROVED`)
        setRequests(INITIAL_STATE)
        closeModal()
    };
    openModal(
        dictionary["absence"]["delete"],
        <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
                {dictionary["absence"]["form"]["from"]}{": "}{moment(request.datefrom).format("DD/MM/YYYY")}
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
                {dictionary["absence"]["form"]["to"]}{": "}{moment(request.dateto).format("DD/MM/YYYY")}
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
                {dictionary["absence"]["days"]}{": "}{request.days}
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                {dictionary["note"]}{": "}{request.notes}
            </Grid>
        </Grid>,
        "warning",
        closeModal => (
            <Stack direction="row" spacing={2}>
                <Button
                    color="success"
                    variant="contained"
                    size="small"
                    onClick={async () => {
                        await action(closeModal);
                    }}>
                    {dictionary["confirm"]}
                </Button>
                <Button
                    color="error"
                    variant="contained"
                    size="small"
                    onClick={closeModal}
                >
                    {dictionary["cancel"]}
                </Button>
            </Stack>
        )
    )
};
export const { Provider, Context } = createDataContext(
    absenceReducer,
    {
        setAbsence,
        setAbsenceTypes,
        addAbsence,
        deleteAbsence
    }, // actions
    INITIAL_STATE // initial state
);
