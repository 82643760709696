import {
  Add,
  HistoryToggleOff,
  InfoOutlined,
  Delete,
} from "@mui/icons-material";
import { Card, IconButton, Stack } from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { PermitsContext, RequestsContext } from "../contexts";
import { useDictionary, useUser } from "../hooks";
import {
  deleteMillisFromTime,
  renderHeader,
  addMonthsToDate,
  translateMinutesInTime,
} from "../utilities";
import HeaderCollapse from "./HeaderCollapse";
import RequestsGeneralPanel from "./RequestsGeneralPanel";

const PermitsPanel = () => {
  const {
    state: { permits },
    setPermits,
    addPermit,
    deletePermit,
  } = useContext(PermitsContext);

  const {user} = useUser();
  const dictionary = useDictionary();

  useEffect(() => {
    setPermits(user.id);
  }, []);

  return (
    <Card>
      <HeaderCollapse
        color={"requests"}
        title={dictionary["dashboard"]["permitsRequests"]}
        notificationCount={permits?.length}
        Icon={HistoryToggleOff}
        actions={
          <Stack direction="row" justifyContent="center" alignItems="center">
            <IconButton
            id="add-permits-request"
              onClick={() => addPermit(user.id, dictionary)}
              sx={{ color: (theme) => theme.palette.common.white }}
              size="small"
            >
              <Add fontSize="small" />
            </IconButton>
            <IconButton
              sx={{ color: (theme) => theme.palette.common.white }}
              size="small"
              component={Link}
              to="/approvers"
            >
              <InfoOutlined fontSize="small" />
            </IconButton>
          </Stack>
        }
      />
      <RequestsGeneralPanel
        requests={permits}
        columns={[
          {
            headerName: dictionary["permits"]["day"],
            renderHeader: renderHeader(dictionary["permits"]["day"]),
            sortable: false,
            field: "datefrom",
            renderCell: ({ value }) =>
              moment(new Date(value)).format("DD/MM/YYYY"),
            flex: 2,
          },
          {
            headerName: dictionary["permits"]["from"],
            renderHeader: renderHeader(dictionary["permits"]["from"]),
            field: "startinghour",
            sortable: false,
            flex: 1,
            renderCell: ({ value }) => {
              return deleteMillisFromTime(value);
            },
          },
          {
            headerName: dictionary["permits"]["to"],
            renderHeader: renderHeader(dictionary["permits"]["to"]),
            sortable: false,
            field: "endinghour",
            flex: 1,
            renderCell: ({ value }) => {
              return deleteMillisFromTime(value);
            },
          },
          {
            headerName: dictionary["permits"]["duration"],
            renderHeader: renderHeader(dictionary["permits"]["duration"]),
            sortable: false,
            field: "minutes",
            renderCell: ({ value }) => {
              return translateMinutesInTime(value);
            },
            flex: 1,
          },
          {
            headerName: dictionary["actions"],
            renderHeader: renderHeader(dictionary["actions"]),
            flex: 1,
            sortable: false,
            field: "",
            renderCell: ({ row }) => {
              if (row.status !== "DELETED" && row.status !== "CANCELLED") {
                return (
                  <Stack direction="row">
                    <IconButton
                      onClick={() =>
                        deletePermit(row, dictionary, setPermits, user.id)
                      }
                      aria-label="delete-request"
                      color="error"
                      size="small"
                    >
                      <Delete fontSize="inherit" />
                    </IconButton>
                  </Stack>
                );
              } else {
                return <></>;
              }
            },
          },
        ]}
      />
    </Card>
  );
};

export default PermitsPanel;
