import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { ThemeContext } from "../contexts";
import { useDictionary, useUser } from "../hooks";
import { routes, userHasOneOfRoles } from "../utilities";
import Route from "./Route";
import UserBadge from "./UserBadge";

const SidebarMenu = () => {
  const location = useLocation();
  const dictionary = useDictionary();
  const { closeSidebar } = useContext(ThemeContext);
  const [opens, setOpens] = useState({});
  const { user } = useUser();

  return (
    <Box
      sx={{
        display: "flex",
        maxHeight: "calc(100% - 64px)",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ overflowY: "auto", flex: 1 }}>
        <List disablePadding>
          {routes.map((route) =>
            route.inSidebar ? (
              route.children &&
              route.children.length > 0 &&
              userHasOneOfRoles(route.need, user) ? (
                <React.Fragment key={route.id}>
                  <ListItemButton
                    id={`menu-${route.id}`}
                    sx={{ mb: 1, pl: 2 }}
                    onClick={() =>
                      setOpens((_) => ({
                        ..._,
                        [route.breadcrumb]: !_[route.breadcrumb],
                      }))
                    }
                  >
                    <ListItemIcon>{route.icon}</ListItemIcon>
                    <ListItemText>
                      {dictionary["routes"][route.breadcrumb]}
                    </ListItemText>
                    {opens[route.breadcrumb] ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse
                    in={opens[route.breadcrumb]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List disablePadding>
                      {route.children.map(
                        (child) =>
                          child.inSidebar && (
                            <Route
                              id={`collaborator-menu-${child.id}`}
                              key={`${child.id}`}
                              route={child}
                              parent={route}
                              icon
                              sx={{ pl: 4, mb: 1 }}
                              close={closeSidebar}
                              location={location}
                              dictionary={dictionary}
                            />
                          )
                      )}
                    </List>
                  </Collapse>
                </React.Fragment>
              ) : (
                <Route
                  id={`menu-${route.path}`}
                  key={`${route.id}`}
                  route={route}
                  icon
                  sx={{ pl: 2, mb: 1 }}
                  close={closeSidebar}
                  location={location}
                  dictionary={dictionary}
                />
              )
            ) : null
          )}
        </List>
      </Box>
      {/* <Box>
        SCOMMENTARE IN CASO SI VUOLE UN FOOTER NELLA SIDEBAR
        <Divider />
        <ListItem>Ciao</ListItem>
        <ListItem>Ciao</ListItem>
        <ListItem>Ciao</ListItem>
        <ListItem>Ciao</ListItem>
      </Box> */}
    </Box>
  );
};

export default SidebarMenu;
