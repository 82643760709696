import React, {useState, useContext, useEffect, useRef} from "react";
import {Button, Grid, Stack, Typography, TextField, FormControlLabel, Checkbox, Box, styled} from "@mui/material";
import moment from "moment";
import {DayBox, ClockingPeriodForm} from "../components";
import {
  createFirstDayOfMonth,
  createFirstDayOfNextMonth,
  ROLES,
  translateMinutesInTime,
  userHasOneOfRoles,
} from "../utilities";
import {AnomaliesContext, BusinesstripsContext, CalendarContext, UserContext} from "../contexts";
import {useDictionary, useUser, useEventTypes, useForm, useMediaQuery, useCostCenters} from "../hooks";
import {Add} from "@mui/icons-material";
import {openModal} from "../hooks/useModals";
import {StaticDatePicker} from "@mui/x-date-pickers";

const INITIAL_STATE = {
  clockings: true,
  requests: true,
  permits: true,
  anomalies: true,
  events: true,
  businesstrips: true,
  bankHours: true,
};

const CustomCalendarPicker = styled(StaticDatePicker)(({theme}) => ({
  "& .PrivatePickersSlideTransition-root": {
    [theme.breakpoints.up("md")]: {
      minHeight: "1000px",
    },
  },
  root: {
    "& > div > div > div > span": {
      width: "100% !important",
    },
    width: "100% !important",
    [theme.breakpoints.up("md")]: {
      maxHeight: "1500px !important",
    },
  },
  viewTransitionContainer: {
    "& div[role=row]": {
      [theme.breakpoints.up("md")]: {
        "& div": {
          width: "113px",
        },
      },
      [theme.breakpoints.up("lg")]: {
        "& div": {
          width: "138px",
        },
      },
      "& div[role=gridcell]": {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      },
      width: "100%",
      justifyContent: "space-around !important",
    },
  },
}));

export default function CalendarModel({id = null}) {
  const timeERef = useRef();
  const dateERef = useRef();
  const timeURef = useRef();
  const dateURef = useRef();
  const [isMobile] = useMediaQuery("down", "md");
  const {
    values: filterCalendar,
    changeHandler,
    setValues,
    errors,
    touched,
    isValid,
    reset,
  } = useForm(INITIAL_STATE, []);

  let date = new Date();

  const [selectedDate, setselectedDate] = useState(date);
  const [selectedDay, setSelectedDay] = useState(date);
  const {user} = useUser();
  const dictionary = useDictionary();
  const eventTypes = useEventTypes();
  const userid = id ? id : user.id;
  const currentUser = !id;
  const commissions = useCostCenters();
  const {
    state: {events, clockings, anomalies, permits, holidays, businesstrips},
    setCalendarUserEvents,
    setCalendarUserClockings,
    setCalendarUserAnomalies,
    setCalendarUserPermits,
    setCalendarUserHolidays,
    setCalendarUserBusinesstrips,
    workingPeriodClock,
    // setDayUserEvents
  } = useContext(CalendarContext);

  const {
    state: {usersStatus},
    setUsersStatus,
  } = useContext(UserContext);

  const {
    state: {bankHours,bankHour},
    /*setBankHours,*/
    setBankHour,
  } = useContext(AnomaliesContext);

  const {addBusinesstrip} = useContext(BusinesstripsContext);

  useEffect(() => {
    if (user && Object.keys(bankHours).length === 0) {
      /*setBankHours(user.id, selectedDate.getFullYear(), selectedDate.getMonth() + 1);*/
      setBankHour(user.id);
    }
  }, [user, bankHours, selectedDate]);

  useEffect(() => {
    const date = new Date(selectedDate);
   /* setBankHours(user.id, date.getFullYear(), date.getMonth() + 1);*/
   setBankHour(user.id);
  }, [selectedDate]);

  useEffect(() => {
    const firstDay = createFirstDayOfMonth(selectedDate);
    const lastDay = createFirstDayOfNextMonth(selectedDate);
    setCalendarUserEvents(userid, firstDay, lastDay);
    setCalendarUserClockings(firstDay, lastDay, currentUser ? null : userid);
    setCalendarUserAnomalies(userid, firstDay, lastDay);
    setCalendarUserPermits(userid, firstDay, lastDay);
    setCalendarUserHolidays(userid, firstDay, lastDay);
    setCalendarUserBusinesstrips(userid, firstDay, lastDay);
    // setSelectedDay(null);
  }, [selectedDate]);

  // useEffect(() => {
  //   if (selectedDay) {
  //     setDayUserEvents(selectedDay, { events, clockings, anomalies, permits, holidays });
  //   }
  // }, [selectedDay]);

  useEffect(() => {
    if (id) {
      setUsersStatus({username: ""});
    }
  }, [id]);

  const printTitle = (isCurrentUser) => {
    let title = dictionary["calendar"]["title"];
    if (!isCurrentUser && usersStatus.length > 0) {
      let foundUser = usersStatus.find((item) => item.id == id);
      title += ` di ${foundUser.firstname} ${foundUser.lastname}`;
    }
    return title;
  };

  // OPEN MODAL METHODS
  const openModalPeriod = (workingPeriod = false) => {
    openModal(
      workingPeriod ? dictionary["calendar"]["addworkingperiod"] : dictionary["calendar"]["addnonworkingperiod"],
      <ClockingPeriodForm
        dictionary={dictionary}
        timeERef={timeERef}
        dateERef={dateERef}
        timeURef={timeURef}
        dateURef={dateURef}
      />,
      null,
      (closeModal) => {
        return (
          <Stack direction="row" spacing={2}>
            <Button
              color="success"
              variant="contained"
              size="small"
              onClick={() =>
                workingPeriodClock(
                  workingPeriod,
                  userid,
                  currentUser,
                  createFirstDayOfMonth(selectedDate),
                  createFirstDayOfNextMonth(selectedDate),
                  dateERef.current,
                  dateURef.current,
                  timeERef.current,
                  timeURef.current,
                  dictionary,
                  closeModal
                )
              }>
              {dictionary["confirm"]}
            </Button>
            <Button color="error" variant="contained" size="small" onClick={closeModal}>
              {dictionary["close"]}
            </Button>
          </Stack>
        );
      },
      "md"
    );
  };
  // END OPEN MODAL METHODS
  return (
    <Stack spacing={2}>
      <CustomCalendarPicker
        views={["year", "month", "day"]}
        minDate={moment("2000-01-01")}
        orientation="portrait"
        openTo="day"
        displayStaticWrapperAs="desktop"
        value={selectedDay}
        showToolbar
        renderDay={
          isMobile
            ? undefined
            : (day) => (
                <DayBox
                  key={`daybox-${moment(day).format("DoDDMM")}`}
                  day={day}
                  commissions={commissions}
                  events={events}
                  clockings={clockings}
                  anomalies={anomalies}
                  permits={permits}
                  holidays={holidays}
                  businesstrips={businesstrips}
                  eventTypes={eventTypes}
                  currentDate={selectedDate}
                  userid={userid}
                  showCalendarObj={filterCalendar}
                />
              )
        }
        ToolbarComponent={(params) => (
          <Grid
            container
            // sx={{ my: theme => theme.spacing(1) }}
            spacing={2}
            direction="row"
            justifyContent="flex-start"
            alignItems="center">
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="h4">{printTitle(currentUser)}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Button
                fullWidth
                startIcon={<Add />}
                color="secondary"
                variant="contained"
                onClick={() => openModalPeriod(true)}>
                {dictionary["calendar"]["addworkingperiod"]}
              </Button>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Button
                fullWidth
                startIcon={<Add />}
                color="secondary"
                variant="contained"
                onClick={() => openModalPeriod(false)}>
                {dictionary["calendar"]["addnonworkingperiod"]}
              </Button>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Button
                fullWidth
                startIcon={<Add />}
                color="secondary"
                variant="contained"
                onClick={() => addBusinesstrip(userid, dictionary)}>
                {dictionary["calendar"]["addbusinesstrip"]}
              </Button>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              {/* {userHasOneOfRoles([ROLES.BANK_HOURS], user) && <BankHoursPanel />} */}
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    color={"clockings"}
                    checked={filterCalendar.clockings}
                    onChange={(newValue) => changeHandler("clockings", newValue.target.checked)}
                  />
                }
                label={dictionary["calendar"]["clockings"]}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color={"requests"}
                    checked={filterCalendar.requests}
                    onChange={(newValue) => changeHandler("requests", newValue.target.checked)}
                  />
                }
                label={dictionary["calendar"]["requests"]}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color={"requests"}
                    checked={filterCalendar.permits}
                    onChange={(newValue) => changeHandler("permits", newValue.target.checked)}
                  />
                }
                label={dictionary["calendar"]["permits"]}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color={"businesstrips"}
                    checked={filterCalendar.businesstrips}
                    onChange={(newValue) => changeHandler("businesstrips", newValue.target.checked)}
                  />
                }
                label={dictionary["calendar"]["businesstrips"]}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color={"anomalies"}
                    checked={filterCalendar.anomalies}
                    onChange={(newValue) => changeHandler("anomalies", newValue.target.checked)}
                  />
                }
                label={dictionary["calendar"]["anomalies"]}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color={"events"}
                    checked={filterCalendar.events}
                    onChange={(newValue) => changeHandler("events", newValue.target.checked)}
                  />
                }
                label={dictionary["calendar"]["events"]}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color={"info"}
                    checked={filterCalendar.bankHours}
                    onChange={(newValue) => changeHandler("bankHours", newValue.target.checked)}
                  />
                }
                label={dictionary["bankHours"]["eventTitle"]}
              />
              {userHasOneOfRoles([ROLES.BANK_HOURS], user) && (
                <Grid item xs={12} md={12} lg={12}>
                  <Typography variant="subtitle2" align={"center"} sx={{border: 1, borderRadius: "5px", p: 1}}>
                    {dictionary["bankHours"]["balance"]}:{" "}
                    { bankHour ? bankHour.balance < 0 ?  "-"+ translateMinutesInTime(- bankHour.balance) : translateMinutesInTime( bankHour.balance):"00:00"}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
        // disable weekend
        // 6 -> saturday
        // 0 -> sunday
        // shouldDisableDate={date => date.toDate().getDay() % 6 === 0}
        onChange={(newValue) => {
          setSelectedDay(newValue);
        }}
        onMonthChange={(newValue) => {
          setselectedDate(newValue);
        }}
        onYearChange={(newValue) => {
          setselectedDate(newValue);
        }}
        renderInput={(params) => <TextField {...params} fullWidth></TextField>}
      />
      <Box sx={{display: {xs: "block", md: "none", lg: "none"}}}>
        <DayBox
          commissions={commissions}
          day={selectedDay}
          events={events}
          clockings={clockings}
          anomalies={anomalies}
          permits={permits}
          holidays={holidays}
          businesstrips={businesstrips}
          eventTypes={eventTypes}
          currentDate={selectedDate}
          userid={userid}
          showCalendarObj={filterCalendar}
        />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <Typography>* {dictionary["calendar"]["associatedJustifications"]}</Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Typography>** {dictionary["calendar"]["associatedReports"]}</Typography>
        </Grid>
        {userHasOneOfRoles([ROLES.BANK_HOURS], user) && (
        <Grid item xs={12} md={6} lg={4}>
          {/* <Typography>*** {dictionary["calendar"]["usedbankhours"]}</Typography> */}
        </Grid>)}
      </Grid>
    </Stack>
  );
}
