import React, { useContext, useEffect } from "react";
import { AbsenceContext } from "../contexts";

const useAbsenceTypes = (mini = false) => {
  const {
    state: { absenceTypes },
    setAbsenceTypes,
  } = useContext(AbsenceContext);

  useEffect(() => {
    if (!mini) {
      setAbsenceTypes();
    }
  }, []);

  return absenceTypes;
};

export default useAbsenceTypes;
